import { FC, useEffect, useRef } from 'react'
import { useVideojs } from 'react-videojs-hook'

import { useAnalyticsEventMutation } from '@alpha/core'

import 'video.js/dist/video-js.css'
import { thisMonth, thisYear } from '~/utils/constants'

import ja from './videojs/languages/ja.json'

type Props = {
  src: string
  thumb: string
  size: {
    height?: number
    width?: number
  }
  className?: string
  onThumbnailLoaded?: () => void
}

const Video: FC<Props> = ({ src, size, thumb, className, onThumbnailLoaded }) => {
  const [submitEvent] = useAnalyticsEventMutation()
  const vjsWrapperRef = useRef<any>();

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src,
    poster: thumb,
    className,
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    language: 'ja',
    languages: { ja },
    controls: true,
    autoplay: false,
    responsive: true,
    onReady: async () => {
      const vjsPoster = vjsWrapperRef.current.getElementsByClassName('vjs-poster')[0]
      const imgVjsPoster = vjsPoster.getElementsByTagName('img')[0]
      // Check img cache
      if (imgVjsPoster.complete) onThumbnailLoaded?.()
      imgVjsPoster.addEventListener('load', function () {
        onThumbnailLoaded?.()
      })
      imgVjsPoster.onload = function () {
        onThumbnailLoaded?.()
      }
    },
    onPlay: async (currentTime) => {
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPlay',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: thisYear,
            month: thisMonth,
            day: new Date().getDate(),
          }),
        },
      })
      // console.log('Video played at:', currentTime)
    },
    onPause: async (currentTime) => {
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPause',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: thisYear,
            month: thisMonth,
            day: new Date().getDate(),
          }),
        },
      })
      // console.log('Video paused at:', currentTime)
    },
    onEnd: async (currentTime) => {
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onEnd',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: thisYear,
            month: thisMonth,
            day: new Date().getDate(),
          }),
        },
      })
      // console.log('Video ended at:', currentTime)
    },
    onTimeUpdate: (currentTime) => {
      // console.log('Video current time is:', currentTime)
    },
  })

  useEffect(() => {
    if (vjsRef.current) {
      (vjsRef.current as any).addEventListener('contextmenu', function (e) {
        e.preventDefault()
      }, false)
    }
  }, [vjsRef])

  return (
    <div data-vjs-player ref={vjsWrapperRef}>
      <video
        ref={vjsRef}
        id={vjsId}
        preload="none"
        className={vjsClassName}
        width={size.width}
        height={size.height}
      />
    </div>
  )
}

export default Video
